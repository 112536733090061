import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/login'
    },
    {path: '/login',
        component: () => import('@/views/LoginView.vue')},
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        children: [{path: '/',component: () => import('@/views/WelcomeView.vue')},
            {path: '/updateTime',component: () => import('@/views/updateTime.vue')},
            {path: '/noticeList',component: () => import('@/components/notice/noticeList.vue')},
            {path: '/pridata',component: () => import('@/components/person/personData.vue')},
            {path: '/updatePwd',component: () => import('@/components/person/updatePwd.vue')},
            {path: '/updatePic',component: () => import('@/components/person/updatePic.vue')},
            {path: '/user', component: ()=>import('@/views/UserView.vue')},

            {path: '/channel', component: ()=>import('@/views/RecruitChannelView.vue')},
            {path: '/needs', component: ()=>import('@/views/RecruitNeedsView.vue')},
            {path: '/resume', component: ()=>import('@/views/RecruitResumeView.vue')},

            
            {path:'/wage',component:()=>import('@/views/WageView.vue')},
            {path:'/basicWage',component:()=>import('@/views/BasicWageView.vue')},
            {path:'/schedule',component:()=>import('@/views/ScheduleView.vue')},

            { path: '/clockrecord', component: () => import('@/views/ClockrecordView.vue') },
            { path: '/employee', component: () => import('../views/EmployeeView.vue') },
            { path: '/employee1', component: () => import('../views/TraineeView.vue') },
            { path: '/employee2', component: () => import('../views/OntrialView.vue') },
            { path: '/employee3', component: () => import('../views/ContractView.vue') },
            { path: '/quit', component: () => import('../views/QuitView.vue') },

          {path:'/clockrecord',component:()=>import('@/views/ClockrecordView.vue')},

          {path:'/leaveapproval',component:()=>import('@/views/LeaveapprovalView.vue')},
          {path:'/overtimeapproval',component:()=>import('@/views/OvertimeapprovalView.vue')},
          {path:'/attendanceinterval',component:()=>import('@/views/AttendanceintervalView.vue')},

          {path: '/employee',component:()=>import('../views/EmployeeView.vue')},
          {path: '/employee1',component:()=>import('../views/TraineeView.vue')},
          {path: '/employee2',component:()=>import('../views/OntrialView.vue')},
          {path: '/employee3',component:()=>import('../views/ContractView.vue')},
          {path: '/quit',component:()=>import('../views/QuitView.vue')},
          {path:'/overtimepayWeekday',component:()=>import('@/views/OvertimepayWeekday.vue')},
          {path:'/overtimepayRestday',component:()=>import('@/views/OvertimepayRestday.vue')},
          {path:'/sickLeaveDeduction',component:()=>import('@/views/SickLeaveDeduction.vue')},
          {path:'/affairLeaveDeduction',component:()=>import('@/views/AffairLeaveDeduction.vue')},
          {path:'/employeeWage',component:()=>import('@/views/EmployeeWage.vue')},
          {path:'/affairLeaveDeduction',component:()=>import('@/views/AffairLeaveDeduction.vue')},
            { path: '/deptment', component: () => import('@/views/DeptmentView.vue') },
            { path: '/positionlevel', component: () => import('@/views/PositionLevelView.vue') },
            { path: '/positiontype', component: () => import('@/views/PositionTypeView.vue') },
            { path: '/position', component: () => import('@/views/PositionView.vue') },
            { path: '/relationship', component: () => import('@/views/UpRelationView.vue') }
    
    
        ]
    },
    {path: '/locking',component: () => import('@/views/LockingView.vue')},
    {path: '/green',component: () => import('@/components/vue-spinner/green.vue')},


]
const router = new VueRouter({
    routes
})

// to：即将要进入的路由
// from：当前路由
// next：回调函数，通过 next 回调函数你可以让 router 去处理路由、取消路由、重定向到其它地方或执行其它操作。
// 配置路由守卫
router.beforeEach((to, from, next) => {
    let token = window.localStorage.getItem('token');

    //判断token
    if (token) {
        let exptime = JSON.parse(atob(token.split('\.')[1])).exp;
        let nowtime = (new Date()).getTime() / 1000;
        if (exptime > nowtime) {

            let lock = localStorage.getItem('lock');
            if (lock !=null && lock=='false') {
                if(to.path == '/locking') {
                    next();
                } else {
                    next('/locking');
                }
                return;
            }

            //token不为空，未过期
            if(to.path == '/login') {
                next('/home')
            } else {
                next()
            }
            return;

        }
    }
    //否则就需要回到登录界面
    to.path == '/login' ? next() : next('/login')

});


export default router