import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import Router from 'vue-router'
import VueAMap from 'vue-amap'
import Vue2OrgTree from 'vue-tree-color'
Vue.use(Vue2OrgTree)
Vue.use(VueAMap);

Vue.config.productionTip = false

VueAMap.initAMapApiLoader({
  key: '845f43f2ff27659c83586ba189858449',  //上面步骤提到的key复制过来
  plugin: ['AMap.AutoComplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
    'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
  v: '1.4.4'
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 引入echarts
import * as echarts from 'echarts'

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

//一般都要加个$加到vue的原型链上，方便引用
Vue.prototype.$echarts = echarts 

Vue.config.productionTip = false

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount('#app')

var vm=new Vue({
    el:'#app',
    router:router,
    components:{'App':App},
    template:`<App></App>`
  });
